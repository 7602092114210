import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as PolygonDownComponent } from '@icons/wolfkit-light/polygon-up.svg';
const ValueTrendContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs / 2,
}));
const definePolygonColor = (palette, trend) => {
    if (trend === 'up') {
        return palette.success.main;
    }
    if (trend) {
        return palette.error.main;
    }
    return palette.text.disabled;
};
const PolygonDown = styled(PolygonDownComponent)(props => ({
    path: {
        fill: definePolygonColor(props.theme.palette, props.trend),
    },
}));
const PolygonUp = styled(PolygonDown)(() => ({
    transform: 'rotateX(180deg)',
}));
const Value = styled.span(props => ({
    fontSize: 14,
    color: definePolygonColor(props.theme.palette, props.trend),
    fontWeight: 500,
}));
const renderPolygon = (trend) => {
    switch (trend) {
        case 'down':
            return _jsx(PolygonDown, { trend: trend });
        case 'up':
            return _jsx(PolygonUp, { trend: trend });
        default:
            return null;
    }
};
const ValueTrend = ({ value = undefined, trend = undefined, addPercentage = undefined, }) => (_jsxs(ValueTrendContainer, { children: [renderPolygon(trend), _jsx(Value, { trend: trend, children: value ? `${value} ${addPercentage ? '%' : ''}` : '' })] }));
export default ValueTrend;
