import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertIcon } from '@icons/wolfkit-light/alert-circle-light.svg';
import Icon from '@shared/ui/icons/Icon';
import Tooltip from '@shared/ui/tooltips/Tooltip';
const RenderAlertIcon = ({ cellParams }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    if (!cellParams.value) {
        return null;
    }
    return (_jsx(Tooltip, { title: t('table.manual_intervention'), placement: 'top', arrow: true, children: _jsx(Icon, { size: 16, color: theme.palette.info.main, IconComponent: AlertIcon }) }));
};
export default RenderAlertIcon;
